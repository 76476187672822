<template>
    <recess-card
        variant="variant1"
        class="px-5 my-3"
    >
        <div class="row">
            <div class="col-12">
                <subtitle-text
                    :subtitle="PROVIDER.Portfolio.CourseEdit.DiplomaTab.Title"
                    qa-class="qa-edit-diploma"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h5 class="mb-4">
                    {{ PROVIDER.Portfolio.CourseEdit.DiplomaTab.Certificate.Title }}
                </h5>
            </div>
            <div class="col-6">
                <recess-multi-select
                    v-model="diplomaData.diplomaType"
                    :selected-options="diplomaData.diplomaType"
                    :options="diplomaTypes"
                    :single-select="true"
                    :select-options-text="diplomaTypes && diplomaTypes[0].displayText"
                    :label-text="PROVIDER.Portfolio.CourseEdit.DiplomaTab.Certificate.DiplomaType.Label"
                    class="qa-course-diploma"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <recess-multi-select
                    v-model="diplomaData.credit.type"
                    :selected-options="diplomaData.credit.type"
                    :options="creditTypes"
                    :single-select="true"
                    :select-options-text="creditTypes && creditTypes[0].displayText"
                    :label-text="PROVIDER.Portfolio.CourseEdit.DiplomaTab.Certificate.CreditType.Label"
                    class="qa-course-credit-type"
                />
            </div>

            <div class="col-2">
                <recess-input
                    :value="diplomaData.credit.value"
                    class="qa-course-credit-value"
                    :label-text="PROVIDER.Portfolio.CourseEdit.DiplomaTab.Certificate.CreditValue.Label"
                    :error-message="creditValueError"
                    @input="(newValue) => onCreditValueInput(newValue)"
                    @blur="$v.diplomaData.credit.value.$touch()"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h5 class="my-4">
                    {{ PROVIDER.Portfolio.CourseEdit.DiplomaTab.Accreditation.Title }}
                </h5>
            </div>

            <div class="col-6">
                <recess-multi-select
                    v-model="diplomaData.accreditation.type"
                    :selected-options="diplomaData.accreditation.type"
                    :options="accreditationTypes"
                    :single-select="true"
                    :label-text="PROVIDER.Portfolio.CourseEdit.DiplomaTab.Accreditation.Type.Label"
                    :select-options-text="accreditationTypes && accreditationTypes[0].displayText"
                    class="qa-course-diploma-accreditation-type"
                />
            </div>

            <div class="col-2">
                <recess-input
                    :value="diplomaData.accreditation.points"
                    class="qa-course-diploma-accreditation-points"
                    :label-text="PROVIDER.Portfolio.CourseEdit.DiplomaTab.Accreditation.Points.Label"
                    :error-message="accreditationPointsError"
                    @input="(newValue) => onAccreditationPointsInput(newValue)"
                    @blur="$v.diplomaData.accreditation.points.$touch()"
                />
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <div class="d-flex">
                    <recess-date-picker
                        v-model="diplomaData.accreditation.validFrom"
                        :default-value="diplomaData.accreditation.validFrom"
                        :label-text="PROVIDER.Portfolio.CourseEdit.DiplomaTab.Accreditation.DateFrom.Label"
                        class="qa-course-diploma-accreditation-valid-from"
                        :error-message="dateValidFromError"
                        date-picker-input-custom-width="170px"
                        @input="onAccreditationValidFromChanged()"
                    />

                    <recess-date-picker
                        v-model="diplomaData.accreditation.validTo"
                        :default-value="diplomaData.accreditation.validTo"
                        :label-text="PROVIDER.Portfolio.CourseEdit.DiplomaTab.Accreditation.DateTo.Label"
                        class="ml-3 qa-course-diploma-accreditation-valid-to"
                        :error-message="dateValidToError"
                        date-picker-input-custom-width="170px"
                        @input="onAccreditationValidToChanged()"
                    />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <recess-divider show-line />
            </div>

            <div class="col-12 mt-3 d-flex justify-content-end align-items-center">
                <recess-button
                    v-if="!isOnCourseEditPage"
                    variant="secondary"
                    :title="BUTTON_TEXT.previousTab"
                    class="qa-previous-tab-button mr-3"
                    @click.prevent.native="handleClickOnTab(null, 'previous-tab')"
                />

                <recess-button
                    variant="secondary"
                    :title="BUTTON_TEXT.save"
                    class="qa-edit-course-submit-button"
                    @click.prevent.native="!isOnCourseEditPage ? createCourse() : handleClickOnTab(null, 'update-course')"
                />
            </div>
        </div>
    </recess-card>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

import { validationMixin } from 'vuelidate'
import { getEnumaratorFromApi } from './courseEditHelpers'

import tabMixin from '../../../mixins/tabNavigationMixin'

import { BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import {
    isEndDateBiggerThanStartDate,
    isValidPositiveDecimalNumberIncludingZero,
    isValidDate,
    isValidDateFormat
} from '@/utils/validationHelpers'

import { dateRangeHasErrorMessage, isInvalidErrorMessage, positiveDecimalNumber } from '@/../../shared/constants/validationMessageHelper.js'

const SubtitleText = () => import('@/components/atoms/SubtitleText')
const StaticAlert = () => import('@/components/atoms/StaticAlert')

export default {
    name: 'EditDiploma',
    components: {
        SubtitleText,
        StaticAlert
    },
    mixins: [validationMixin, tabMixin],
    inject: ['diplomaCourseData'],
    data() {
        return {
            PROVIDER,
            BUTTON_TEXT,
            accreditationTypes: [
                {
                    displayText: PROVIDER.Portfolio.CourseEdit.DiplomaTab.SelectAccreditationTypes.SelectOption,
                    value: ''
                }
            ],
            creditTypes: [
                {
                    displayText: PROVIDER.Portfolio.CourseEdit.DiplomaTab.SelectCreditTypes.SelectOption,
                    value: ''
                }
            ],
            diplomaTypes: [
                {
                    displayText: PROVIDER.Portfolio.CourseEdit.DiplomaTab.SelectDiplomaTypes.SelectOption,
                    value: ''
                }
            ],
            getEnums: true,
            diplomaData: {
                accreditation: {
                    type: '',
                    typeDisplayValue: '',
                    typeOther: '',
                    validFrom: '',
                    validTo: '',
                    points: ''
                },
                credit: {
                    type: '',
                    typeDisplayValue: '',
                    value: ''
                }
            }
        }
    },
    validations: {
        diplomaData: {
            accreditation: {
                validFrom: {
                    isValidDate,
                    isValidDateFormat
                },
                validTo: {
                    isValidDate,
                    isValidDateFormat,
                    validateEndDateBiggerThanStartDate() {
                        return isEndDateBiggerThanStartDate(
                            this.diplomaData.accreditation.validFrom,
                            this.diplomaData.accreditation.validTo
                        )
                    }
                },
                points: {
                    isValidPositiveDecimalNumberIncludingZero
                }
            },
            credit: {
                value: {
                    isValidPositiveDecimalNumberIncludingZero
                }
            }
        }
    },
    computed: {
        accreditationPointsError() {
            return this.$v.diplomaData.accreditation.points.$error &&
                !this.$v.diplomaData.accreditation.points.isValidPositiveDecimalNumberIncludingZero
                ? positiveDecimalNumber
                : null
        },
        creditValueError() {
            return this.$v.diplomaData.credit.value.$error &&
                !this.$v.diplomaData.credit.value.isValidPositiveDecimalNumberIncludingZero
                ? positiveDecimalNumber
                : null
        },
        dateValidFromError() {
            return isInvalidErrorMessage(
                PROVIDER.Portfolio.CourseEdit.DiplomaTab.Accreditation.DateFrom.Label,
                this.$v.diplomaData.accreditation.validFrom,
                'isValidDate'
            )
        },
        dateValidToError() {
            if (!this.$v.diplomaData.accreditation.validTo.$error) return null

            return (
                isInvalidErrorMessage(
                    PROVIDER.Portfolio.CourseEdit.DiplomaTab.Accreditation.DateTo.Label,
                    this.$v.diplomaData.accreditation.validTo,
                    'isValidDate'
                ) ||
                dateRangeHasErrorMessage(
                    'maxDate',
                    this.$v.diplomaData.accreditation.validTo,
                    'validateEndDateBiggerThanStartDate',
                    { startDate: 'startdatum', endDate: 'vervaldatum' }
                )
            )
        }
    },
    watch: {
        diplomaCourseData: {
            handler() {
                if (Object.entries(this.diplomaCourseData.diplomaData).length !== 0 && this.diplomaCourseData.diplomaData.constructor === Object) {
                    this.diplomaData = JSON.parse(JSON.stringify(this.diplomaCourseData.diplomaData))

                    this.getData()
                }
            },
            immediate: true,
            deep: true
        },
        course: {
            handler() {
                if (this.getEnums) {
                    this.getAccreditationTypeEnums()
                    this.getCreditTypeEnums()
                    this.getDiplomaTypeEnums()
                }
            }
        },
        diplomaData: {
            handler() {
                this.$emit('triggerProgressChange', this.diplomaData)
            },
            deep: true
        }
    },
    methods: {
        updateEditedCourse() {
            this.$v.$touch()

            if (!this.$v.$invalid) {
                this.$emit('submitForm')
            } else {
                this.emitValidation()
            }
        },
        createCourse() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.$emit('createCourse')
            }
        },
        async getData() {
            const accreditationTypeEnumsResponse = await getEnumaratorFromApi('accreditationType')
            const creditTypeEnumsResponse = await getEnumaratorFromApi('creditType')
            const diplomaTypeEnumsResponse = await getEnumaratorFromApi('diplomaType')

            const filteredAccreditationTypeEnums = accreditationTypeEnumsResponse.reduce((accumulator, accreditation) => {
                if (accreditation.value === 'NBA') {
                    accreditation.disabled = true
                }
                return [...accumulator, accreditation]
            }, [])
            this.accreditationTypes = [...this.accreditationTypes, ...filteredAccreditationTypeEnums]

            this.creditTypes = [...this.creditTypes, ...creditTypeEnumsResponse]
            this.diplomaTypes = [...this.diplomaTypes, ...diplomaTypeEnumsResponse]
        },
        onAccreditationValidFromChanged() {
            this.$v.diplomaData.accreditation.validTo.$touch()
            this.$v.diplomaData.accreditation.validFrom.$touch()
        },
        onAccreditationValidToChanged() {
            this.$v.diplomaData.accreditation.validFrom.$touch()
            this.$v.diplomaData.accreditation.validTo.$touch()
        },
        onCreditValueInput(newValue) {
            this.diplomaData.credit.value = newValue
        },
        onAccreditationPointsInput(newValue) {
            this.diplomaData.accreditation.points = newValue
        }
    }
}
</script>
